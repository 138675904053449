import { pickedApiRequest } from './request'

export default {
  /**
   * Create candidates
   *
   * @param {string} organisationId
   * @param {Object} data
   * @return {Array}
   */
  create (organisationId, data) {
    return pickedApiRequest.post('assessment/organisations/' + organisationId + '/candidates', data)
      .then(response => {
        return response.data.data
      })
  },

  /**
   * Organisation’s candidates
   *
   * @param {string} organisationId
   * @param {Object} query
   * @return {Array}
   */
  index (organisationId, query) {
    return pickedApiRequest.get('assessment/organisations/' + organisationId + '/candidates', {
      params: query
    })
      .then(response => {
        return response.data
      })
  },

  /**
   * Organisation’s candidate
   *
   * @param {string} organisationId
   * @param {string} candidateId
   * @return {Object}
   */
  candidate (organisationId, candidateId) {
    return pickedApiRequest.get('assessment/organisations/' + organisationId + '/candidates/' + candidateId)
      .then(response => {
        return response.data.data
      })
  },

  /**
   * Save a candidate’s jobs
   *
   * @param {string} organisationId
   * @param {string} candidateId
   * @return {Array}
   */
  updateCandidateJobs (organisationId, candidateId, jobUuids) {
    return pickedApiRequest.post('assessment/organisations/' + organisationId + '/candidate-jobs/' + candidateId, {
      jobUuids
    })
      .then(response => {
        return response.data.data
      })
  },

  /**
   * Resend tests to candidates
   *
   * @param {string} organisationId
   * @param {string} candidateId
   * @return {Array}
   */
  candidateReminder (organisationId, candidateId) {
    return pickedApiRequest.post('assessment/organisations/' + organisationId + '/candidates-reminder', {
      id: candidateId
    })
      .then(response => {
        return response.data.data
      })
  },

  /**
   * Updates a candidate
   *
   * @param {string} organisationId
   * @param {string} candidateId
   * @param {Object} data
   * @return {Array}
   */
  update (organisationId, candidateId, data) {
    return pickedApiRequest.post('assessment/organisations/' + organisationId + '/candidates/' + candidateId, data)
      .then(response => {
        return response.data.data
      })
  },

  /**
   * Resets an exam for a candidate
   *
   * @param {string} organisationId
   * @param {string} candidateId
   * @param {string} examSlug
   * @param {Number} sittingId
   * @return {Array}
   */
  resetExam (organisationId, candidateId, examSlug, sittingId) {
    return pickedApiRequest.post('assessment/organisations/' + organisationId + '/candidates/' + candidateId + '/reset-exams', {
      examSlug,
      sittingId
    })
      .then(response => {
        return response.data.data
      })
  },

  /**
   * Deletes a candidate and their data
   *
   * @param {string} organisationId
   * @param {Array} candidateIds
   * @return {Array}
   */
  deleteCandidates (organisationId, candidateIds) {
    return pickedApiRequest.post('assessment/organisations/' + organisationId + '/delete-candidates', {
      candidateIds
    })
      .then(response => {
        return response.data.data
      })
  },

  /**
   * Uploads multiple candidates
   * when Candidate Wizard is active
   *
   * @param {string} organisationId
   * @param {Array} candidates
   * @return {Array}
   */
  uploadCandidates (organisationId, candidatesAndJob) {
    return pickedApiRequest.post('assessment/organisations/' + organisationId + '/invite-candidates', candidatesAndJob)
      .then(response => {
        return response.data.data
      })
  },

  /**
   * Uploads multiple candidates
   * when Classic Jobs is active
   *
   * @param {string} organisationId
   * @param {Array} candidates
   * @return {Array}
   */
  uploadCandidatesBatch (organisationId, candidatesAndJob) {
    return pickedApiRequest.post('assessment/organisations/' + organisationId + '/candidates-batch', candidatesAndJob)
      .then(response => {
        return response.data.data
      })
  },

  /**
   * @param {string} organisationId
   * @param {Array} candidates
   * @return {Array}
   */
  inviteCandiatesBatch (organisationId, data) {
    return pickedApiRequest.post('assessment/organisations/' + organisationId + '/candidates-invite', data)
      .then(response => {
        return response.data
      })
  },

  /**
   * Gets list of an organisation's
   * invited candidates
   *
   * @param {string} organisationId
   * @param {Object} query
   * @return {Array}
   */
  candidateInvitations (organisationId, query) {
    return pickedApiRequest.get('assessment/organisations/' + organisationId + '/candidate-invitations', {
      params: query
    })
      .then(response => {
        return response.data
      })
  }
}
