<template>
  <div v-if="state === states.READY">
    <BasePageHeader>
      <div>
        <BasePageBreadcrumbs :breadcrumbs="breadcrumbs"></BasePageBreadcrumbs>
        <BasePageHeading>Candidates</BasePageHeading>
      </div>
    </BasePageHeader>

    <BaseTabBar>
      <BaseTabs>
        <!-- <BaseTab
          :to="{ name: 'candidates' }"
          :active="true"
        >
          Testing <span>({{ paginationData.totalItems.toLocaleString() }})</span>
        </BaseTab> -->
      </BaseTabs>
      <div class="mb-3">
        <SearchBox
          :search-query="searchQuery"
          placeholder="Search candidates"
          @search="onSearch($event)"
        >
        </SearchBox>
        <!-- @TODO Place back in -->
        <!-- <CustomSelect
          v-if="openJobs"
          placeholder="All jobs"
          :options="openJobs"
          @selected="filterJob($event)"
        /> -->
      </div>
    </BaseTabBar>

    <div v-if="candidates">
      <div v-if="candidates.length > 0">
        <div class="max-w-screen-xl mx-auto">
          <div class="overflow-x-auto px-6">
            <CandidatesTable
              :show-search="false"
              :columns="columns"
              :table-data="candidates"
              :pagination-data="paginationData"
              :sort-data="sortData"
              @pagechanged="onPageChange($event)"
              @sort="onSort($event)"
              @refreshCandidates="fetchCandidates()"
              @candidateUpdated="candidateUpdated($event)"
            />
          </div>
        </div>
      </div>
      <div
        v-else
        class="max-w-screen-xl mx-auto px-6"
      >
        <CandidatesEmpty @createdCandidates="fetchCandidates()"/>
      </div>
    </div>
  </div>
  <div v-else-if="state === states.LOADING">
    <BasePageHeader>
      <div>
        <BasePageBreadcrumbs></BasePageBreadcrumbs>
        <BasePageHeading></BasePageHeading>
      </div>
    </BasePageHeader>
    <Loader />
  </div>
  <div v-else-if="state === states.ERROR">
    <BaseErrorBlock />
  </div>
</template>

<script>
import CandidatesEmpty from '@components/Candidates/CandidatesEmpty'
import CandidatesTable from '@components/Candidates/CandidatesTable'
// import CustomSelect from '@components/CustomSelect'
import Loader from '@components/Loader'
import SearchBox from '@components/Candidates/SearchBox'

import organisationCandidatesApi from '@api/organisationCandidates'
import { mapGetters } from 'vuex'
import states from '@api/states'

export default {
  components: {
    CandidatesEmpty,
    CandidatesTable,
    // CustomSelect,
    Loader,
    SearchBox
  },

  data() {
    return {
      states,

      // Sort from here

      jobId: null,
      jobName: null,
      searchQuery: null,
      searchSortBy: null,
      searchSortOrder: null,

      columns: [
        { key: 'name', sortable: true },
        { key: 'email', sortable: false },
        { key: 'tests', sortable: false }
      ],

      initialTab: 'testing',
      activeTab: 'testing',
      tabs: ['testing'],

      candidates: null,

      paginationData: {
        currentPage: 1,
        perPage: 50,
        totalPages: null,
        totalItems: 0
      },

      sortData: {
        key: null,
        order: null
      }
    }
  },

  computed: {
    ...mapGetters({
      haveJobsLoaded: 'jobs/haveJobsLoaded',
      openJobs: 'jobs/openJobs',
      organisationId: 'employers/organisationId',
      organisationName: 'organisations/name'
    }),

    /**
     * @return {Array}
     */
    breadcrumbs() {
      return [
        {
          name: this.organisationName
        }
      ]
    },

    /**
     * @return {string}
     */
    state() {
      if (this.error) {
        return states.ERROR
      }
      if (!this.candidates) {
        return states.LOADING
      }

      return states.READY
    },

    jobsSelect() {
      if (!this.jobs) {
        return
      }
      return this.jobs.map(job => {
        return {
          value: job.uuid,
          label: job.name
        }
      })
    },

    /**
     * Returns the current filters for the search
     *
     * @TODO 2019-11-26 Add text search
     *
     * @return {Object}
     */
    query() {
      return {
        job_id: this.jobId,
        page: this.paginationData.currentPage,
        per_page: this.paginationData.perPage,
        search: this.searchQuery,
        sort_by: this.searchSortBy,
        sort_order: this.searchSortOrder
      }
    }
  },

  mounted() {
    if (!this.haveJobsLoaded) {
      this.$store.dispatch('jobs/getJobs')
    }
    this.fetchCandidates()
  },

  methods: {
    /**
     * Fetch the candidates based on the current query
     */
    fetchCandidates() {
      this.candidates = null
      return organisationCandidatesApi.index(this.organisationId, this.query)
        .then(data => {
          this.candidates = data.data
          if (data.meta) {
            this.paginationData.currentPage = data.meta.current_page
            this.paginationData.totalItems = data.meta.total
            this.paginationData.totalPages = data.meta.last_page
            // @NOTE 2019-12-19 Add in if required
            // this.paginationData.from = data.meta.from
            // this.paginationData.to = data.meta.to
            this.sortData.key = data.meta.sort_by
            this.sortData.order = data.meta.sort_order
          }
        })
        .catch(error => {
          this.candidates = []
          throw error
        })
    },

    /**
     * One candidate has been updated, replace inline if possible
     *
     * @param {Object} updatedCandidate
     */
    candidateUpdated(updatedCandidate) {
      const candidate = this.candidates.find(candidate => {
        return candidate.id === updatedCandidate.id
      })

      if (!candidate) {
        this.fetchCandidates()
        return
      }

      const candidateIndex = this.candidates.indexOf(candidate)

      this.$set(this.candidates, candidateIndex, updatedCandidate)
    },

    /**
     * Filter has been chosen for a job
     *
     * @param {Object} job
     */
    filterJob(job) {
      this.jobId = job.id
      this.jobName = job.name
      this.fetchCandidates()
    },

    /**
     * When the page changes...
     *
     * @param {Number} page
    */
    onPageChange(page) {
      this.paginationData.currentPage = page
      this.fetchCandidates()
    },

    /**
     * User selects a sort by
     *
     * @param {string} key
    */
    onSort({ key, order }) {
      console.log('Sort by ' + key + ' in ' + order)
      this.searchSortBy = key
      this.searchSortOrder = order
      this.fetchCandidates()
    },

    /**
     * Replace search query and trigger fetch attempts
     *
     * @param {string}
     */
    onSearch(query) {
      this.searchQuery = query
      this.fetchCandidates()
    }
  }
}
</script>
