<template>
  <div>
    <header class="pb-8 max-w-screen-xl mx-auto flex justify-between items-center px-6 h-20">
      <h1 class="text-2xl leading-tight">
        Candidates
      </h1>

      <InviteCandidatesButton />
    </header>

    <div class="border-b-2 mb-6">
      <div class="flex justify-between max-w-screen-xl mx-auto px-6">
        <Tab
          :tabs="tabs"
          :initial-tab="initialTab"
          @activeTab="activeTab = $event"
        >
          <template slot="tab-head-allCandidates">
            <div class="inline-flex">
              <p class="mr-1">
                Registered
              </p>
              <span v-if="totalCandidatesRegistered !== 0">({{ totalCandidatesRegistered.toLocaleString() }})</span>
              <span v-else>
                <svg
                  class="animate-spin h-5 w-5 text-secondary"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <circle
                    class="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    stroke-width="4"
                  ></circle>
                  <path
                    class="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                  ></path>
                </svg>
              </span>
            </div>
          </template>
          <template slot="tab-head-invitedCandidates">
            <div class="inline-flex">
              <p class="mr-1">
                Invited
              </p>
              <span v-if="totalCandidatesInvited !== 0">({{ totalCandidatesInvited.toLocaleString() }})</span>
              <span v-else>
                <svg
                  class="animate-spin h-5 w-5 text-secondary"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <circle
                    class="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    stroke-width="4"
                  ></circle>
                  <path
                    class="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                  ></path>
                </svg>
              </span>
            </div>
          </template>
        </Tab>

        <CustomSelect
          v-if="openJobs"
          placeholder="All jobs"
          :options="openJobs"
          @selected="filterJob($event)"
        />
      </div>
    </div>
    <SearchBox
      :search-query="searchQuery"
      placeholder="Search candidates"
      class="hidden sm:block mb-6"
      @search="onSearch($event)"
    >
    </SearchBox>
    <div v-if="candidates">
      <div v-if="candidates.length > 0">
        <div class="max-w-screen-xl mx-auto">
          <div class="overflow-x-auto px-6">
            <CandidatesListTable
              :show-search="false"
              :columns="columns"
              :table-data="candidates"
              :pagination-data="paginationData"
              :sort-data="sortData"
              :active-tab="activeTab"
              @pagechanged="onPageChange($event)"
              @sort="onSort($event)"
            />
          </div>
        </div>
      </div>
      <div
        v-else
        class="max-w-screen-xl mx-auto px-6"
      >
        <CandidatesEmpty @createdCandidates="fetchCandidates()"/>
      </div>
    </div>
    <div v-else>
      <Loader />
    </div>
  </div>
</template>

<script>
import InviteCandidatesButton from '@components/Candidates/InviteCandidatesButton'
import CandidatesEmpty from '@components/Candidates/CandidatesEmpty'
import CandidatesListTable from '@components/Candidates/CandidatesListTable'

import CustomSelect from '@components/CustomSelect'
import Loader from '@components/Loader'
import SearchBox from '@components/Candidates/SearchBox'
import Tab from '@components/Tab'

import organisationCandidatesApi from '@api/organisationCandidates'
import { mapGetters } from 'vuex'

export default {
  components: {
    InviteCandidatesButton,
    CandidatesEmpty,
    CandidatesListTable,
    CustomSelect,
    Loader,
    SearchBox,
    Tab
  },

  data() {
    return {
      jobId: null,
      jobName: null,
      searchQuery: null,
      searchSortBy: null,
      searchSortOrder: null,

      columns: [
        { key: 'name', sortable: true },
        { key: 'email', sortable: false },
        { key: 'Date invited', sortable: false }
      ],

      initialTab: 'allCandidates',
      activeTab: 'allCandidates',
      tabs: ['allCandidates', 'invitedCandidates'],

      candidates: null,

      paginationData: {
        currentPage: 1,
        perPage: 50,
        totalPages: null,
        totalItems: 0
      },

      sortData: {
        key: null,
        order: null
      },

      totalCandidatesRegistered: 0,
      totalCandidatesInvited: 0
    }
  },

  computed: {
    ...mapGetters({
      haveJobsLoaded: 'jobs/haveJobsLoaded',
      openJobs: 'jobs/openJobs',
      organisationId: 'employers/organisationId'
    }),

    jobsSelect() {
      if (!this.jobs) {
        return
      }
      return this.jobs.map(job => {
        return {
          value: job.uuid,
          label: job.name
        }
      })
    },

    /**
     * Returns the current filters for the search
     *
     * @TODO 2019-11-26 Add text search
     *
     * @return {Object}
     */
    query() {
      return {
        job_id: this.jobId,
        page: this.paginationData.currentPage,
        per_page: this.paginationData.perPage,
        search: this.searchQuery,
        sort_by: this.searchSortBy,
        sort_order: this.searchSortOrder
      }
    }
  },

  watch: {
    activeTab(tab) {
      this.paginationData.currentPage = 1

      if (tab === 'invitedCandidates') {
        this.fetchInvitedCandidates()
      } else {
        this.fetchCandidates()
      }
    }
  },

  mounted() {
    if (!this.haveJobsLoaded) {
      this.$store.dispatch('jobs/getJobs')
    }
    this.fetchInvitedCandidates()
    this.fetchCandidates()
  },

  methods: {
    /**
     * Fetch the candidates based on the current query
     */
    fetchCandidates() {
      this.candidates = null
      return organisationCandidatesApi.index(this.organisationId, this.query)
        .then(data => {
          this.candidates = data.data
          if (data.meta) {
            this.totalCandidatesRegistered = data.meta.total

            this.paginationData.currentPage = data.meta.current_page
            this.paginationData.totalItems = data.meta.total
            this.paginationData.totalPages = data.meta.last_page
            // @NOTE 2019-12-19 Add in if required
            // this.paginationData.from = data.meta.from
            // this.paginationData.to = data.meta.to
            this.sortData.key = data.meta.sort_by
            this.sortData.order = data.meta.sort_order
          }
        })
        .catch(error => {
          this.candidates = []
          throw error
        })
    },

    /**
     * Fetch the candidates based on the current query
     */
    fetchInvitedCandidates() {
      this.candidates = null
      return organisationCandidatesApi.candidateInvitations(this.organisationId, this.query)
        .then(data => {
          this.candidates = data.data
          if (data.meta) {
            this.totalCandidatesInvited = data.meta.total

            this.paginationData.currentPage = data.meta.current_page
            this.paginationData.totalItems = data.meta.total
            this.paginationData.totalPages = data.meta.last_page
            this.sortData.key = data.meta.sort_by
            this.sortData.order = data.meta.sort_order
          }
        })
        .catch(error => {
          this.candidates = []
          throw error
        })
    },

    /**
     * Filter has been chosen for a job
     *
     * @param {Object} job
     */
    filterJob(job) {
      this.jobId = job.id
      this.jobName = job.name
      this.fetchCandidates()
    },

    /**
     * When the page changes...
     *
     * @param {Number} page
    */
    onPageChange(page) {
      this.paginationData.currentPage = page

      if (this.activeTab === 'invitedCandidates') {
        this.fetchInvitedCandidates()
      } else {
        this.fetchCandidates()
      }
    },

    /**
     * User selects a sort by
     *
     * @param {string} key
    */
    onSort({ key, order }) {
      console.log('Sort by ' + key + ' in ' + order)
      this.searchSortBy = key
      this.searchSortOrder = order

      if (this.activeTab === 'invitedCandidates') {
        this.fetchInvitedCandidates()
      } else {
        this.fetchCandidates()
      }
    },

    /**
     * Replace search query and trigger fetch attempts
     *
     * @param {string}
     */
    onSearch(query) {
      this.searchQuery = query

      if (this.activeTab === 'invitedCandidates') {
        this.fetchInvitedCandidates()
      } else {
        this.fetchCandidates()
      }
    }
  }
}
</script>
