<template>
  <div class="bg-white w-full">
    <div class="p-10 sm:flex-shrink-0 text-center mx-auto">
      <Percentages class="max-w-sm mx-auto" />
      <div class="max-w-md mx-auto">
        <h2 class="text-3xl my-6">
          {{ title }}
        </h2>
        <InviteCandidatesButton
          v-if="$usesFeature('candidate-wizard') && job"
          :job="job"
        />

        <template v-if="$usesFeature('candidate-wizard') && !job">
          <p class="my-4">
            Add a job to get started
          </p>
          <BaseButton
            :to="{name: 'job-create'}"
          >
            <template slot="iconLeft">
              <Icon
                width="12px"
                height="12px"
                view-box="0 0 14 14"
                class="mr-4"
              >
                <Plus />
              </Icon>
            </template>
            Add job
          </BaseButton>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import Percentages from '@components/Vectors/Percentages'
import InviteCandidatesButton from '@components/Candidates/InviteCandidatesButton'
import Icon from '@components/Icons/Icon'
import Plus from '@components/Icons/Plus'

export default {
  components: {
    Percentages,
    InviteCandidatesButton,
    Icon,
    Plus
  },

  props: {
    job: {
      type: Object,
      default: null
    },
    title: {
      type: String,
      default: 'It’s a bit empty here'
    }
  }
}
</script>
